*,
*::before,
*::after {
  box-sizing: inherit;
  padding: 0;
  margin: 0;
}

html {
  box-sizing: border-box;
  font-family: sans-serif;
  line-height: 1.15;
}

main {
  display: block;
}

h1 {
  margin: 0.67em 0;
  font-size: 2em;
}

a {
  background-color: transparent;
}

abbr[title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

code,
kbd,
samp,
pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

button,
input,
optgroup,
select,
textarea {
  line-height: inherit;
  border: 1px solid currentColor;
}

button {
  overflow: visible;
  text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  padding: 1px 6px;
}

input {
  overflow: visible;
}

input,
textarea {
  padding: 1px;
}

fieldset {
  margin: 0 2px;
  border: 1px solid currentColor;
}

legend {
  display: table;
  max-width: 100%;
  color: inherit;
  white-space: normal;
}

progress {
  display: inline-block;
  vertical-align: baseline;
}

select {
  text-transform: none;
}

textarea {
  overflow: auto;
  vertical-align: top;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="color"] {
  background: inherit;
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

::input-placeholder {
  color: inherit;
  opacity: 0.5;
}

::-webkit-search-decoration,
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

::-moz-focus-inner {
  border: 0;
}

:-moz-focusring {
  outline: 1px dotted ButtonText;
}

:-moz-ui-invalid {
  box-shadow: none;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  color: inherit;
}

dl,
ol,
ul {
  margin: 1em 0;
}

ol ol,
ol ul,
ol dl,
ul ol,
ul ul,
ul dl,
dl ol,
dl ul,
dl dl {
  margin: 0;
}

b,
strong {
  font-weight: bolder;
}

audio,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

table {
  text-indent: 0;
  border-color: inherit;
}

details {
  display: block;
}

dialog {
  position: absolute;
  right: 0;
  left: 0;
  display: block;
  width: fit-content;
  width: fit-content;
  width: fit-content;
  height: fit-content;
  height: fit-content;
  height: fit-content;
  padding: 1em;
  margin: auto;
  color: inherit;
  background-color: inherit;
  border: solid;
}

dialog:not([open]) {
  display: none;
}

summary {
  display: list-item;
}

canvas {
  display: inline-block;
}

template {
  display: none;
}

[hidden] {
  display: none;
}
